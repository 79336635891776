/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      city
      country
      address
      description
      headlineAct
      eventDate
      eventTime
      campaignId
      ownerId
      image
      EventDays {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      city
      country
      address
      description
      headlineAct
      eventDate
      eventTime
      campaignId
      ownerId
      image
      EventDays {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      city
      country
      address
      description
      headlineAct
      eventDate
      eventTime
      campaignId
      ownerId
      image
      EventDays {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEventDay = /* GraphQL */ `
  mutation CreateEventDay(
    $input: CreateEventDayInput!
    $condition: ModelEventDayConditionInput
  ) {
    createEventDay(input: $input, condition: $condition) {
      id
      title
      event {
        id
        name
        city
        country
        address
        description
        headlineAct
        eventDate
        eventTime
        campaignId
        ownerId
        image
        createdAt
        updatedAt
      }
      tickets {
        nextToken
      }
      createdAt
      updatedAt
      eventEventDaysId
    }
  }
`;
export const updateEventDay = /* GraphQL */ `
  mutation UpdateEventDay(
    $input: UpdateEventDayInput!
    $condition: ModelEventDayConditionInput
  ) {
    updateEventDay(input: $input, condition: $condition) {
      id
      title
      event {
        id
        name
        city
        country
        address
        description
        headlineAct
        eventDate
        eventTime
        campaignId
        ownerId
        image
        createdAt
        updatedAt
      }
      tickets {
        nextToken
      }
      createdAt
      updatedAt
      eventEventDaysId
    }
  }
`;
export const deleteEventDay = /* GraphQL */ `
  mutation DeleteEventDay(
    $input: DeleteEventDayInput!
    $condition: ModelEventDayConditionInput
  ) {
    deleteEventDay(input: $input, condition: $condition) {
      id
      title
      event {
        id
        name
        city
        country
        address
        description
        headlineAct
        eventDate
        eventTime
        campaignId
        ownerId
        image
        createdAt
        updatedAt
      }
      tickets {
        nextToken
      }
      createdAt
      updatedAt
      eventEventDaysId
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      campaignId
      eventId
      name
      amount
      description
      qty
      eventDay {
        id
        title
        createdAt
        updatedAt
        eventEventDaysId
      }
      content
      active
      suspendedByUser
      createdAt
      updatedAt
      eventDayTicketsId
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      campaignId
      eventId
      name
      amount
      description
      qty
      eventDay {
        id
        title
        createdAt
        updatedAt
        eventEventDaysId
      }
      content
      active
      suspendedByUser
      createdAt
      updatedAt
      eventDayTicketsId
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      campaignId
      eventId
      name
      amount
      description
      qty
      eventDay {
        id
        title
        createdAt
        updatedAt
        eventEventDaysId
      }
      content
      active
      suspendedByUser
      createdAt
      updatedAt
      eventDayTicketsId
    }
  }
`;
export const createUserData = /* GraphQL */ `
  mutation CreateUserData(
    $input: CreateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    createUserData(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      createdAt
      updatedAt
    }
  }
`;
export const updateUserData = /* GraphQL */ `
  mutation UpdateUserData(
    $input: UpdateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    updateUserData(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserData = /* GraphQL */ `
  mutation DeleteUserData(
    $input: DeleteUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    deleteUserData(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      createdAt
      updatedAt
    }
  }
`;
export const createVendorData = /* GraphQL */ `
  mutation CreateVendorData(
    $input: CreateVendorDataInput!
    $condition: ModelVendorDataConditionInput
  ) {
    createVendorData(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      createdAt
      updatedAt
    }
  }
`;
export const updateVendorData = /* GraphQL */ `
  mutation UpdateVendorData(
    $input: UpdateVendorDataInput!
    $condition: ModelVendorDataConditionInput
  ) {
    updateVendorData(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      createdAt
      updatedAt
    }
  }
`;
export const deleteVendorData = /* GraphQL */ `
  mutation DeleteVendorData(
    $input: DeleteVendorDataInput!
    $condition: ModelVendorDataConditionInput
  ) {
    deleteVendorData(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      createdAt
      updatedAt
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelCartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      amount
      createdAt
      updatedAt
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelCartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      amount
      createdAt
      updatedAt
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelCartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      amount
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      orderAmount
      paymentStatus
      paymentReference
      fullName
      country
      active
      scanned
      eventDate
      eventTime
      eventLocation
      bookingUserName
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      orderAmount
      paymentStatus
      paymentReference
      fullName
      country
      active
      scanned
      eventDate
      eventTime
      eventLocation
      bookingUserName
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      orderAmount
      paymentStatus
      paymentReference
      fullName
      country
      active
      scanned
      eventDate
      eventTime
      eventLocation
      bookingUserName
      createdAt
      updatedAt
    }
  }
`;
export const createOrderTicket = /* GraphQL */ `
  mutation CreateOrderTicket(
    $input: CreateOrderTicketInput!
    $condition: ModelOrderTicketConditionInput
  ) {
    createOrderTicket(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      amount
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderTicket = /* GraphQL */ `
  mutation UpdateOrderTicket(
    $input: UpdateOrderTicketInput!
    $condition: ModelOrderTicketConditionInput
  ) {
    updateOrderTicket(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      amount
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderTicket = /* GraphQL */ `
  mutation DeleteOrderTicket(
    $input: DeleteOrderTicketInput!
    $condition: ModelOrderTicketConditionInput
  ) {
    deleteOrderTicket(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      amount
      createdAt
      updatedAt
    }
  }
`;
export const createUserInfo = /* GraphQL */ `
  mutation CreateUserInfo(
    $input: CreateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    createUserInfo(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const updateUserInfo = /* GraphQL */ `
  mutation UpdateUserInfo(
    $input: UpdateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    updateUserInfo(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserInfo = /* GraphQL */ `
  mutation DeleteUserInfo(
    $input: DeleteUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    deleteUserInfo(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      name
      description
      image
      active
      ownerId
      minAmount
      genre
      country
      approved
      declined
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      name
      description
      image
      active
      ownerId
      minAmount
      genre
      country
      approved
      declined
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      name
      description
      image
      active
      ownerId
      minAmount
      genre
      country
      approved
      declined
      createdAt
      updatedAt
    }
  }
`;
