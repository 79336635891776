import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Animated, ImageBackground, FlatList, ScrollView, Image, } from 'react-native'
import React, {useState, useEffect, useRef} from 'react';
import Header from './Components/Header';
import { useNavigation } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Entypo } from '@expo/vector-icons'; 
import { FlashList } from "@shopify/flash-list";
import * as queries from './src/graphql/queries';
import { Video, ResizeMode } from 'expo-av';


import { useStateContext } from './src/contexts/ContextProvider';

import { Auth, API, graphqlOperation } from 'aws-amplify';
import { getUserData } from './src/graphql/queries';

const {width, height} = Dimensions.get('screen')



const HomeMain = () => {
  const {counting, setCounting, authed, setAuthed, authedUser, setAuthedUser} = useStateContext();
    const imageBack = 'https://images.unsplash.com/photo-1633465784393-d0c2da7cc9cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'
    const navigation = useNavigation();
    const joyus = 'https://lh3.googleusercontent.com/cWmb1yIGgUNIEYfX6Z8Lsg-X-5m4VjOt7nCS6UvyBy54Xad-_HRAu_wi6VbaTE4t-mPtb7vSPP9tAvAaaRSgafz5Zn16VfYBWw'
    const ourOrange = '#FF4F00';

    const scrollX = new Animated.Value(0);

    const bannerY = useRef(new Animated.Value(0)).current;


    let position  = Animated.divide(scrollX, width)
    const [userInfo, setUserInfo] = useState();



  const checkAuth = async()=>{


    try {
      await Auth.currentAuthenticatedUser().then((ef)=> {
       
        return
      })
    } catch (error) {
     
      setAuthed(false)
    }



    const userAuthenticated =  await Auth.currentAuthenticatedUser();
    const userSub = userAuthenticated.attributes.sub;

    try{
      await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((ef)=> {
      const userd = ef.data.getUserData

        setAuthedUser({
          email: userd.email,
          firstName: userd.firstName
        })

      setUserInfo(userd),
      setAuthed(true)

      return
    }).catch((ee)=> {
      console.error(ee),
      setAuthed(false)
    })

} catch (e){
  console.error(e),
  setAuthed(false)
}

  }

  useEffect(() => {
    checkAuth();

    Animated.timing(bannerY, {
      toValue: 0,
      duration: 750,
      delay: 500,
      useNativeDriver: true,
    }).start();

      
  }, [userInfo])




    const bannerList = [
      {
        id: 1,
        title: 'Learning Apps',
        sub: 'Educaitonal applications for early development',
        image: 'https://images.unsplash.com/photo-1601299124348-6a43706a155d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'
      },
      {
        id: 2,
        title: 'Delivery',
        sub: 'On-demand or tranditional delivery app',
        image: 'https://images.pexels.com/photos/9461218/pexels-photo-9461218.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
      },
      {
        id: 3,
        title: 'OTT Streaming',
        sub: 'Music or video streaming',
        image: 'https://images.pexels.com/photos/3756859/pexels-photo-3756859.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
      },
      
    ]

    const socialList = [
      {
        id: 1,
        image: ''
      }
    ]




    const renderBanner = ({item}) => {
      return(
    <ImageBackground imageStyle={{borderRadius: 0}} style={{width: width, elevation: 0, height: height / 1.5, borderRadius: 0}}   source={{uri: item?.image}} >
          <LinearGradient  
                style={{width: '100%', height: '100%', alignItems: 'center' , justifyContent: 'flex-end'}}
                colors={['rgba(0,0,0,0.3)', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0.6)']}>

                  <Animated.View style={{borderRadius: 10, backgroundColor: 'rgba(0,0,0,0.6)', width: width - 30, padding: 10, alignItems: 'center', marginBottom: 20}}>
                      <Text style={{fontWeight: 'bold', fontSize: 20, color: '#fcfcfc', alignSelf: 'center', textAlign: 'center'}}>{item?.title}</Text>
                      <View style={{flexDirection: 'row', alignSelf: 'center', alignItems: 'center', marginTop: 10}}>
                        <Feather name='info' color={'white'} size={15} />
                        <Text style={{fontSize: 12, color: '#fcfcfc', marginLeft: 20}}>{item?.sub}</Text>
                      </View>

                  
                      <Animated.View style={{height: 30, width: 100, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 15, marginBottom: 25}}>
                        <TouchableOpacity onPress={()=> navigation.navigate('EmptyScreen', {item: item})} style={{flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
                          <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc', marginRight: 5}}>Read</Text>
                          <Feather name= 'arrow-up-right' size={10} color={'#fcfcfc'} />
                        </TouchableOpacity>
                      </Animated.View>

                  </Animated.View>

                  

                </LinearGradient>
     </ImageBackground>
      )
    }


    const showBanner = () => {

      return(
        <View>
          <FlatList
                    data={bannerList}
                    keyExtractor={(item, index) => 'key'+index}
                    horizontal
                    pagingEnabled
                    scrollEnabled
                    snapToAlignment='center'
                    scrollEventThrottle={16}
                    decelerationRate={"normal"}
                    showsHorizontalScrollIndicator={false}
                    renderItem={renderBanner}
                    onScroll = {Animated.event(
                        [{nativeEvent: {contentOffset: {x: scrollX}}},
                        
                        ], {useNativeDriver: false}
                    )}


                 />

                 <Animated.View style={[styles.dotView,{marginTop: 15, backgroundColor: 'rgba(0,0,0,0.9)', position: 'absolute', zIndex: 9000, top: 40, right: 20}]}>
                    {bannerList.map((_, i) => {
                      let opacity = position.interpolate({
                            inputRange: [i - 1, i, i + 1],
                            outputRange: [0.2, 1, 0.2],
                            extrapolate: 'clamp'
                        })

                        return(
                            <Animated.View key={i}
                            style={{opacity, height: 7, width: 7, backgroundColor: 'black', margin: 8, borderRadius: 5, marginTop: -30}}
                              />
                        )
                    })}
                 </Animated.View>

                 </View>
      )
      

    }

    const imageCat = 'https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'

    const textColour = '#1e3036';

    const whitelabel = 'https://images.unsplash.com/photo-1603208636525-8825c33ed34b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'
    const partnering = 'https://images.unsplash.com/photo-1611432579402-7037e3e2c1e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=765&q=80'
  
  
    return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <View style={{zIndex: 9000}}>
            <Header/>
        </View>
        <ScrollView style={{height: height}}>
        {/* hero */}
        <Animated.View style={{height: height/1.5, width: width, marginTop: 0, transform: [{translateY: bannerY}]}}>
          {showBanner()}
        </Animated.View>
       
        <View style={{height: 20, width: width, backgroundColor: '#edf1f2'}} />

        <Animated.View style={{width: width, paddingBottom: 30}}>
      <Text style={{margin: 15, fontWeight: 'bold', fontSize: 18, color: textColour}}>From our socials</Text>

      <Animated.View style={{}}>
        <ScrollView horizontal 
        showsVerticalScrollIndicator={false}
        style={{paddingLeft: 15}}>
          <View style={{}}>
            <Image 
              source={require('./assets/images/twa.png')}
              style={{height: width/1.5, width: width/1.5 ,resizeMode: 'contain'}}
            /> 
          </View>

          <View style={{}}>
            <Image 
              source={require('./assets/images/grocery.JPG')}
              style={{height: width/1.5, width: width/1.5 ,resizeMode: 'contain'}}
            /> 
          </View>

          <View style={{}}>
            <Image 
              source={require('./assets/images/a.JFIF')}
              style={{height: width/1.5, width: width/1.5 ,resizeMode: 'contain'}}
            /> 
          </View>
        </ScrollView>
      </Animated.View>


        </Animated.View>

        <View style={{height: 20, width: width, backgroundColor: '#edf1f2'}} />

       
        <Animated.View style={{width: width, backgroundColor: '#fcfcfc', padding: 15}}>

          <View style={{alignSelf: 'center', marginBottom: 20}}>
            <Image source={require('./assets/gifs/drum.gif')} style={{height: 150, width: 150, resizeMode: 'contain'}}/>
          </View>


          <View>
            <Text style={{fontSize: 14, color: textColour, fontStyle: 'italic', textAlign: 'justify'}}>We are a software development company that does not sell or develop cheap apps. One of our core targets is to build a stable bridge between funded and non-funded projects. 

          </Text>
          </View>


          <Text style={{fontSize: 14, color: textColour, fontStyle: 'italic', marginTop: 15, textAlign: 'justify'}}>We strive to ensure that disadvantaged startups with promise and drive have access to premium software development services. We offer our services across the African continent, and we are committed to helping startups in Africa succeed.</Text>

          <Text style={{fontSize: 14, color: textColour, fontStyle: 'italic', marginTop: 15, textAlign: 'justify'}}>We believe that everyone has the right to access quality software development services, regardless of their funding status. We are passionate about helping startups achieve their goals, and we believe that our services can make a real difference in the lives of entrepreneurs across Africa.</Text>

          <Text style={{fontSize: 14, color: textColour, fontStyle: 'italic', marginTop: 15, textAlign: 'justify'}}>If you are a startup in Africa, we encourage you to contact us to learn more about our services. We would be happy to discuss your project and how we can help you bring it to life.</Text>


</Animated.View>
<View style={{height: 20, width: width, backgroundColor: '#edf1f2'}} />

<Animated.View style={{width: width, paddingBottom: 0}}>
      <Text style={{margin: 15, fontWeight: 'bold', fontSize: 18, color: textColour}}>Video</Text>

      <Animated.View style={{width: width}}>
        <Video 
          useNativeControls
          source={require('./assets/video/bui.mp4')}
          style={{width: '100%'}}
          resizeMode='contain'
          onReadyForDisplay={videoData => {
            videoData.srcElement.style.position = "initial"
          }}
        />


      </Animated.View>


</Animated.View>  
        
<View style={{height: 20, width: width, backgroundColor: '#edf1f2'}} />

<Animated.View style={{width: width, paddingBottom: 0}}>
      <Text style={{margin: 15, fontWeight: 'bold', fontSize: 18, color: textColour}}>White-labelling</Text>

      <Animated.View style={{}}>
      <ImageBackground imageStyle={{borderRadius: 0}} style={{width: width, elevation: 0, height: height / 1.5, borderRadius: 0}}   source={{uri: whitelabel}} >
          <LinearGradient  
                style={{width: '100%', height: '100%', alignItems: 'center' , justifyContent: 'flex-end'}}
                colors={['rgba(0,0,0,0.3)', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0.6)']}>

                  <Animated.View style={{borderRadius: 10, backgroundColor: 'rgba(0,0,0,0.6)', width: width - 30, padding: 10, alignItems: 'center', marginBottom: 20}}>
                      <Text style={{fontWeight: 'bold', fontSize: 20, color: '#fcfcfc', alignSelf: 'center', textAlign: 'center'}}>Ready made solutions</Text>
                      <View style={{flexDirection: 'row', alignSelf: 'center', alignItems: 'center', marginTop: 10}}>
                        <Text style={{fontSize: 12, color: '#fcfcfc', marginLeft: 20}}>fast to production, quick activation in less than a month</Text>
                      </View>

                  
                      <Animated.View style={{height: 30, width: 100, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 15, marginBottom: 25}}>
                        <TouchableOpacity onPress={()=> navigation.navigate('Whitelabel')} style={{flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
                          <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc', marginRight: 5}}>Read</Text>
                          <Feather name= 'arrow-up-right' size={10} color={'#fcfcfc'} />
                        </TouchableOpacity>
                      </Animated.View>

                  </Animated.View>

                  

                </LinearGradient>
     </ImageBackground>
      </Animated.View>


</Animated.View>
<View style={{height: 20, width: width, backgroundColor: '#edf1f2'}} />

<Animated.View style={{width: width, paddingBottom: 0}}>
      <Text style={{margin: 15, fontWeight: 'bold', fontSize: 18, color: textColour}}>App - Partnering</Text>

      <Animated.View style={{}}>
      <ImageBackground imageStyle={{borderRadius: 0}} style={{width: width, elevation: 0, height: height / 1.5, borderRadius: 0}}   source={{uri: partnering}} >
          <LinearGradient  
                style={{width: '100%', height: '100%', alignItems: 'center' , justifyContent: 'flex-end'}}
                colors={['rgba(0,0,0,0.3)', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0.6)']}>

                  <Animated.View style={{borderRadius: 10, backgroundColor: 'rgba(0,0,0,0.6)', width: width - 30, padding: 10, alignItems: 'center', marginBottom: 20}}>
                      <Text style={{fontWeight: 'bold', fontSize: 20, color: '#fcfcfc', alignSelf: 'center', textAlign: 'center'}}>Need a technical partner ?</Text>
                      <View style={{flexDirection: 'row', alignSelf: 'center', alignItems: 'center', marginTop: 10}}>
                        {/* <Feather name='info' color={'white'} size={15} /> */}
                        <Text style={{fontSize: 12, color: '#fcfcfc', marginLeft: 20, textAlign: 'center'}}>We build and partner with you in development and post production</Text>
                      </View>

                  
                      <Animated.View style={{height: 30, width: 100, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 15, marginBottom: 25}}>
                        <TouchableOpacity onPress={()=> navigation.navigate('EmptyScreen')} style={{flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
                          <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc', marginRight: 5}}>Read</Text>
                          <Feather name= 'arrow-up-right' size={10} color={'#fcfcfc'} />
                        </TouchableOpacity>
                      </Animated.View>

                  </Animated.View>

                  

                </LinearGradient>
     </ImageBackground>
      </Animated.View>


</Animated.View>
<View style={{height: 20, width: width, backgroundColor: '#edf1f2'}} />

        {/* <Animated.View style={{height: height / 3, width: width, backgroundColor: '#1e1f24', marginTop: 25, padding: 25}}>
            <View style={{}}>
              <Image source={require('./assets/nblogo.png')} style={{width: 80, height: 60, resizeMode: 'contain'}} />
            </View>

            <View style={{flexDirection: 'row', justifyContent: 'space-evenly'}}>
              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>About Us</Text>
              </TouchableOpacity>

              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>List your event</Text>
              </TouchableOpacity>

              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Help</Text>
              </TouchableOpacity>
            </View>

            <View style={{flexDirection: 'row', marginTop: 25, justifyContent: 'space-evenly'}}>
              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>FAQs</Text>
              </TouchableOpacity>

              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Ts n Cs</Text>
              </TouchableOpacity>

              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Privacy Policy</Text>
              </TouchableOpacity>
            </View>

            <View style={{flexDirection: 'row', marginTop: 25, justifyContent: 'space-evenly'}}>
              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Chat to us</Text>
              </TouchableOpacity>

              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Find Us</Text>
              </TouchableOpacity>

              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Download Apps</Text>
              </TouchableOpacity>
            </View>
            
            <Text style={{fontSize: 12, color: '#fcfcfc', alignSelf: 'center', marginTop: 30}}>A product of REDSNAGISH (PTY)LTD</Text>
            <Text style={{fontSize: 10, color: '#fcfcfc', alignSelf: 'center', marginTop: 5}}>Developed by Sunday Creatives (Pty) Ltd</Text>
        </Animated.View> */}

        </ScrollView>


    </View>
  )
}

export default HomeMain

const styles = StyleSheet.create({
  dotView: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
  }
})