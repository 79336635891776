import { View, Text, Dimensions, TouchableOpacity, ImageBackground, Animated, ScrollView } from 'react-native'
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Foundation } from '@expo/vector-icons';
import Header from '../Components/Header';
const {width, height} = Dimensions.get('screen')

const AboutUs = () => {

    const navigation = useNavigation();

    const textColour = '#1e3036';

    const headerImage = 'https://images.unsplash.com/photo-1553775927-a071d5a6a39a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1087&q=80'
    const mandelaImage = 'https://images.unsplash.com/photo-1549113579-e225cc3dd1eb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=580&q=80'
  return (
    <View style={{flex: 1, backgroundColor: '#ccd6d9'}}>
        <View style={{zIndex: 12000}}>
            <Header />
        </View>
        

        {/* <View style={{height: 60, flexDirection: 'row', alignItems: 'center', width: width, backgroundColor: '#fcfcfc', paddingHorizontal: 20, marginTop: 5}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{flexDirection: 'row', alignItems: 'center'}}>
                <Feather name='arrow-left' size={20} color={textColour} />

                <Text style={{marginLeft: 15, fontSize: 16, fontWeight: 'bold'}}>Who are we ?</Text>
            </TouchableOpacity>
        </View> */}
        <ImageBackground style={{height: width / 2, width: width, marginTop: 5}} source={{uri: headerImage}}>
            <Animated.View style={{backgroundColor: 'rgba(0,0,0,0.6)', width: width, height: '100%'}}>
                <View style={{position: 'absolute', left: 20, bottom: 20}}>
                    <Text style={{fontWeight: 'bold', color: 'orange', }}>Who are we ?</Text>
                </View>
            </Animated.View>
        </ImageBackground>

        <ScrollView>
            <Animated.View style={{padding: 15, marginHorizontal: 10, marginTop: 10, backgroundColor: '#fcfcfc', borderRadius:5}}>
                <Animated.View style={{marginTop: 20}}>
                    
                <Text style={{fontSize: 16, fontWeight: 'bold', fontStyle: 'italic', color: textColour, alignSelf: 'center', textAlign: 'justify', width: width / 1.5}}>Sunday Creatives is a modern software development agency in South Africa regarded as one of the best and transformative agencies in the African Continet</Text>

                </Animated.View>

                <Animated.View style={{marginTop: 15, width: '100%'}}>
                </Animated.View>

            </Animated.View>

            <Animated.View style={{padding: 15, marginHorizontal: 10, marginTop: 10, backgroundColor: '#fcfcfc', borderRadius:5}}>
                
                <Animated.View style={{marginTop: 15, width: '100%'}}>
                <Text style={{fontSize: 12, textAlign: 'justify'}}>We specialise in a wide range of software development services, including but not limited to mobile app development, web development, and custom software development. The company has a proven track record of success in delivering high-quality software solutions that meet the needs of its clients</Text>
                </Animated.View>

                <Animated.View style={{marginTop: 15, width: '100%'}}>
                    <Text style={{fontSize: 12, textAlign: 'justify'}}>Sunday Creatives is committed to providing its clients with the best possible service. The company has a team of experienced and knowledgeable consultants who are available to help clients with every stage of the software development process while simultaneously offering a variety of support services to ensure that its clients are satisfied with their software solutions post development</Text>
                </Animated.View>

            </Animated.View>


            <Animated.View style={{padding: 0, marginHorizontal: 10, marginTop: 10, marginBottom: 10, backgroundColor: '#fcfcfc', borderRadius:5}}>
                             <ImageBackground style={{height: 150, width: '100%', borderRadius: 5, alignItems: 'center', justifyContent: 'center'}} imageStyle={{borderRadius: 5}} source={{uri: mandelaImage}}>
                             <Animated.View style={{backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', height: '100%', borderRadius: 5, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 40}}>
                                    <Text style={{color: 'white', fontSize: 12, fontStyle: 'italic'}}>Do not judge me by my success, judge me by how many times I fell down and got back up again</Text>
                                    <Text style={{color: 'white', fontSize: 12, fontStyle: 'italic'}}>- Nelson R. Mandela</Text>

                            </Animated.View>
                             </ImageBackground>
            </Animated.View>
        </ScrollView>
        
      
    </View>
  )
}

export default AboutUs