import { View, Text, Dimensions, TouchableOpacity, Animated, Image } from 'react-native'
import React, {useState, useRef, useEffect} from 'react'
const {width, height} = Dimensions.get('screen')
import { useNavigation } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Foundation } from '@expo/vector-icons'; 
import { useStateContext } from '../src/contexts/ContextProvider';
import { Auth, API, graphqlOperation } from 'aws-amplify';


const Header = () => {

    const navigation = useNavigation();
    const drawer = useRef(new Animated.Value(width)).current;
    const heightMenu = useRef(new Animated.Value(0)).current;
    const newMenu = useRef(new Animated.Value(0)).current;
    const opaMenu = useRef(new Animated.Value(0)).current;

    const {counting, setCounting, authed, setAuthed, setAuthedUser, authedUser} = useStateContext();

    const showDrawer  = ()=> {

        Animated.parallel([
            Animated.spring(drawer, {
                toValue: 0,
                useNativeDriver: true
            })
        ]).start()

    }

    const closeDrawer  = ()=> {

        Animated.parallel([
            Animated.spring(drawer, {
                toValue: width,
                useNativeDriver: true
            })
        ]).start()

    }

    const signout = async()=> {
        try {
             await Auth.signOut().then((e)=> {
                setAuthed(false),
                closeDrawer(),
                navigation.navigate('Home')
             })   
        } catch (error) {
          console.error(error)  
        }
    }

    const showNewMenu = async()=> {

        setOpen(!sopen)


        Animated.parallel([
            Animated.spring(heightMenu, {
                toValue: 200,
                useNativeDriver: true
            }), 
            Animated.spring(newMenu, {
                toValue: 0,
                useNativeDriver: true
            }), 
            Animated.spring(opaMenu, {
                toValue: 1,

                useNativeDriver: true
            }), 
        ]).start()
    }

    const closeNewMenu = async()=> {

        setOpen(!sopen)

        Animated.parallel([
            Animated.spring(heightMenu, {
                toValue: 0,
                useNativeDriver: true
            }), 
            Animated.spring(newMenu, {
                toValue: 0,
                useNativeDriver: true
            }), 
            Animated.spring(opaMenu, {
                toValue: 0,

                useNativeDriver: true
            }), 
        ]).start()
    }







    const [sopen, setOpen] = useState(false)


  return (
    <View style={{backgroundColor: '#fcfcfc', width: width}}>

        <Animated.View style={{height: 60, width: width, backgroundColor: '#fcfcfc', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 15, paddingBottom: 15}}>
            
                

            <TouchableOpacity onPress={()=> navigation.navigate('Home')} style={{height: '100%', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: 15, backgroundColor: 'transparent'}}>
                <Image  source={require('../assets/dar.png')} style={{resizeMode: 'contain', width: 100, height: 45, marginTop: 15}}  />
            </TouchableOpacity>
            

            <Animated.View style={{marginRight: 20, height: '100%', justifyContent: 'center', paddingTop: 15}}>
              
                {/* <TouchableOpacity onPress={()=> showDrawer()}> */}
                <TouchableOpacity onPress={()=> {!sopen ? showNewMenu() : closeNewMenu()}}>
                
                    <AntDesign name="appstore-o" size={20} color="black" />
                </TouchableOpacity>

            </Animated.View>
            
            
        </Animated.View>

        <Animated.View style={{height: heightMenu, backgroundColor: '#fcfcfc',  width: width, transform: [{translateY: newMenu}]}}>
                <Animated.View style={{ paddingHorizontal: 15, opacity: opaMenu, height: heightMenu}}>
                    <TouchableOpacity onPress={()=> {closeDrawer(), navigation.navigate('AboutUs')}} style={{paddingTop: 20}}>
                        <Text style={{fontSize: 12}}>WHO ARE WE ?</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=> {closeDrawer(), navigation.navigate('EmptyScreen')}} style={{paddingTop: 20}}>
                        <Text style={{fontSize: 12}}>SERVICES</Text>
                    </TouchableOpacity>

                 
                    <TouchableOpacity onPress={()=> {closeDrawer(), navigation.navigate('EmptyScreen')}} style={{paddingTop: 20}}>
                        <Text style={{fontSize: 12}}>OUR WORK</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=> {closeDrawer(), navigation.navigate('EmptyScreen')}} style={{paddingTop: 20}}>
                        <Text style={{fontSize: 12}}>CAREERS</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=> {closeDrawer(), navigation.navigate('EmptyScreen')}} style={{paddingTop: 20}}>
                        <Text style={{fontSize: 12}}>CONTACT</Text>
                    </TouchableOpacity>
                </Animated.View>
        </Animated.View>




         




    </View>
  )
}

export default Header