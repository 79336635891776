
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Image, ActivityIndicator, TextInput, Animated } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Auth, API } from 'aws-amplify';
const {width, height} = Dimensions.get('screen');
import { useStateContext } from '../src/contexts/ContextProvider';



const LoginFlow = () => {

    const {counting, setCounting, authed, setAuthed} = useStateContext();
    const ourOrange = '#FF4F00';

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [spinning, setSpinning] = useState('')

    const signIn = async()=> {

        setSpinning(true)

        if(username === ''){
            setErrorMsg('Please enter an email address')
            setSpinning(false)
            return
        }

        if(password === ''){
            setErrorMsg('Please enter your password')
            setSpinning(false)
            return
        }

        try {
            await Auth.signIn(username, password).then((e)=> {
                setSpinning(false),
                setAuthed(true) 
                
            })
        } catch (error) {
            console.error(error),
            setErrorMsg(error.message),
            setSpinning(false)
        }


           
    }


  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc', padding: 20}}>
    <Text style={{marginTop: 15, fontSize: 16, fontWeight: 'bold' }}>Log in</Text>
    <Text style={{fontSize: 12, color: 'gray'}}>Looks like you are not signed in</Text>

    {errorMsg && <Text style={{marginBottom: 5, fontSize: 12, color: 'red'}}>{errorMsg}</Text>}
    <View style={{flexDirection: 'row', marginTop: 30}}>
      <TextInput placeholder='E-Mail Address' value={username} onChangeText={setUsername} style={{borderWidth: 0.5, width: width - 20, height: 45, paddingLeft: 20, borderRadius: 5}} />
    </View>

    <View style={{ marginTop: 30}}>
      <TextInput placeholder='Password' secureTextEntry value={password} onChangeText={setPassword} style={{borderWidth: 0.5, width: width - 40, height: 45, paddingLeft: 20, borderRadius: 5}} />
      
      <TouchableOpacity style={{marginTop: 5}}>
        <Text style={{fontSize: 12}}>Forgot Password ? </Text>
      </TouchableOpacity>
      
    
    </View>

    <Animated.View>
      <TouchableOpacity onPress={()=> signIn()} style={{height: 45, width: width - 40, marginTop: 30,  backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5}}>
        
       {spinning &&  <View style={{position: 'absolute', left: 10}}>
                <ActivityIndicator size={'small'} color={'#fcfcfc'} />
            </View> }
        <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>Sign In</Text>
      </TouchableOpacity>

      <View>
        <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
          <Text style={{fontSize: 12}}>Don't have an account ? </Text>
          <TouchableOpacity style={{marginLeft: 5}}>
            
            <Text style={{fontSize: 12, fontWeight: 'bold', color: ourOrange}}>Create An Account</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Animated.View>


  </View>
  )
}

export default LoginFlow

const styles = StyleSheet.create({})