import { StyleSheet, Text, View, Image } from 'react-native'
import React from 'react'
import Header from '../Components/Header'

const EmptyScreen = () => {


    const textColour = '#1e3036';

  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc', alignItems: 'center', justifyContent: 'center'}}>
        <View style={{zIndex: 12000, position: 'absolute', top: 0}}>
            <Header/>
        </View>
        
      <Text style={{fontWeight: 'bold', color: textColour, opacity: 0.5, marginBottom: 20}}>Eish... this page is still unavailabe</Text>
      <Image source={require('../assets/dar.png')} style={{height: 50, width: 80, resizeMode: 'contain'}} />
    </View>
  )
}

export default EmptyScreen

const styles = StyleSheet.create({})