import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Image, ActivityIndicator, TextInput, Animated, FlatList, ImageBackground } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons'; 
import Header from '../Components/Header';
import { Auth, API } from 'aws-amplify';
import * as queries from '../src/graphql/queries';
import * as mutations from '../src/graphql/mutations';
const {width, height} = Dimensions.get('screen');
import { useStateContext } from '../src/contexts/ContextProvider';
import EmptyComp from '../Components/EmptyComp';
import LoginFlow from '../Components/LoginFlow';
import { useNavigation } from '@react-navigation/native';


const Cart = () => {
   const {counting, setCounting, authed, setAuthed} = useStateContext();

   const navigation = useNavigation()
  const [ticketsall, setTicketsAll] = useState([])

  const [spinning, setSpinning] = useState(false);
  const [showLoaded, setShowLoaded] = useState(false)

  const fetchingTickets = async()=> {

  
    if(authed){
      const userIdd = await Auth.currentAuthenticatedUser({bypassCache: true})

    

      const todoData = await API.graphql({query: queries.listCarts, variables:  
          {filter :   { userId: 
                  {eq: 
                      (userIdd?.attributes.sub)
                  },
                  }
           }
      })
          const todos = todoData.data.listCarts.items
          setTicketsAll(todos)
    }


    
}

useEffect(()=> {
    fetchingTickets();
}, [ticketsall, authed])


const ourOrange = '#FF4F00';
const [qty, setQty] = useState()


  if(!authed){
    return (
      <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <View style={{padding: 20}}>
          <TouchableOpacity onPress={()=> navigation.goBack()}>
            <Feather size={30} name='arrow-left' />
          </TouchableOpacity>
        </View>
        <LoginFlow/>
      </View>
    )
    
     
  }


    const renderTickets = ({item, index})=> {

      const addQty = async() =>{

        setSpinning(true)

        const addOne = {
          id: item?.id,
          qty: parseFloat(item?.qty) + parseFloat('1'),
      }

      try {
        await API.graphql({ query: mutations.updateCart, variables: {input: addOne}}).then((e)=> {
          setSpinning(false)
        })
      } catch (error) {
        console.error(error),
        setSpinning(false)
      }

    }

      const minusQty = async() =>{
        if(item?.qty !== 1){
    
          setSpinning(true)
          const addOne = {
            id: item?.id,
            qty: parseFloat(item?.qty) - parseFloat('1'),
        }
    
        try {
          await API.graphql({ query: mutations.updateCart, variables: {input: addOne}}).then((e)=> {
            setSpinning(false)
          })
        } catch (error) {
          console.error(error),
          setSpinning(false)
        }
        
        } else if(item?.qty === 1){
          deleteItem()
        }
    
    
    
      }


      const deleteItem = async()=>{
        setSpinning(false)
        const addOne = {
          id: item?.id
        }
    
        try {
          await API.graphql({ query: mutations.deleteCart, variables: {input: addOne}}).then((e)=> {
            setSpinning(false)
          })
        } catch (error) {
          console.error(error),
          setSpinning(false)
        }
    
      }

      return(
        <TouchableOpacity onPress={()=> navigation.navigate('TicketDetails', {item: item})} style={{height: 100, width: '100%', flexDirection: 'row', alignItems: 'center', backgroundColor: '#fcfcfc', borderRadius: 10, paddingLeft: 10}}>
          <ImageBackground 
            source={{uri: item.image}}
            imageStyle={{borderRadius: 10}}
            style={{height: 75, width: 85, resizeMode: 'stretch', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}
          >
            {spinning && 
            <View style={{height: 60, width: 60, borderRadius: 10, backgroundColor: 'rgba(0,0,0,0.6)', alignItems: 'center', justifyContent: 'center'}}>
              <ActivityIndicator size={'small'} color={'white'} />
            </View>
            }
          </ImageBackground>

            <View style={{marginLeft: 20}}>
              <Text style={{fontSize: 12, fontWeight: 'bold'}}>{String(item.title).slice(0,25)}</Text>
            <View style={{flexDirection: 'row', marginTop: 10}}>
              <Text style={{fontSize: 12}}>R{Number(item.amount).toFixed(2)}</Text>


            </View>

            <View style={{flexDirection: 'row', marginTop: 10}}>
              <TouchableOpacity onPress={()=> minusQty()} style={{height: 30, width: 30, borderRadius: 5, alignItems: 'center', justifyContent: 'center', backgroundColor: ourOrange}}>
                <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>-</Text>
              </TouchableOpacity>


              <View style={{height: 30, width: 50, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontWeight: 'bold', fontSize: 12}}>{item.qty}</Text>
              </View>

              <TouchableOpacity onPress={()=> addQty()} style={{height: 30, width: 30, borderRadius: 5, alignItems: 'center', justifyContent: 'center', backgroundColor: ourOrange}}>
                <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>+</Text>
              </TouchableOpacity>


              <TouchableOpacity onPress={()=> deleteItem()} style={{height: 30, width: 30, marginLeft: 20, borderRadius: 5, alignItems: 'center', justifyContent: 'center', backgroundColor: 'red'}}>
                <MaterialCommunityIcons name='delete-forever-outline' color={'#fcfcfc'} size={15} />
              </TouchableOpacity>
            </View>


          </View>

          
          

        </TouchableOpacity>
      )
    }

    const itemSeperator = () => {
      return(
        <View style={{height: 10}} />
      )
    }


    let totalPrice = 0;

    totalPrice = ticketsall?.reduce((summedPrice, product) => 
    (summedPrice + (product?.amount || 0) * product?.qty ), 
    0);



    if(ticketsall?.filter(cp => !cp.product).length === 0){

      const showingSwitch = () => {

        setInterval(() => {
          setShowLoaded(true)
        }, 7000);


        if(showLoaded === false){
          return(
            <View style={{alignItems: 'center', justifyContent: 'center', flex: 1}}>
                 <View style={{marginBottom: 0, marginTop: 0, alignItems: 'center', justifyContent: 'center'}}>

              <Image source={require('../assets/iconsmsall.png')} style={{height: 30, width: 80, resizeMode: 'contain'}} />
          <Text style={{marginTop: 15}}>Checking your cart...</Text>

          <ActivityIndicator size={'small'} color={'purple'} style={{marginTop: 10}} />

          </View>

          
     
            </View>
          )   
        } else {
          return(
            <View style={{alignItems: 'center', justifyContent: 'center', flex: 1}}>
                 <View style={{marginBottom: 0, marginTop: 0, alignItems: 'center', justifyContent: 'center'}}>

              <Image source={require('../assets/iconsmsall.png')} style={{height: 30, width: 80, resizeMode: 'contain', opacity: 0.5}} />
          <Text style={{marginTop: 15, fontWeight: 'bold', fontSize: 18, opacity: 0.4}}>Your cart is empty</Text>

            <TouchableOpacity onPress={()=> navigation.navigate('Search')} style={{flexDirection: 'row', marginTop: 15, alignItems: 'center', justifyContent: 'center', padding: 10, borderRadius: 5, backgroundColor: 'purple'}}>
              <Feather name='search' size={15} color={'#fcfcfc'} />
              <Text style={{fontSize: 12,color: '#fcfcfc', marginLeft: 5, fontWeight: 'bold'}}>Search Tickets</Text>
            </TouchableOpacity>

          </View>

          
     
            </View>
          )
        }


      }



        return(
          <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
            <View style={{zIndex: 19000}}>
              <Header/>
            </View>
            {showingSwitch()}
          </View>
        )
    }







  return (
    <View style={{flex: 1, backgroundColor: '#edf1f2'}}>
        <View style={{zIndex: 19000}}>
         <Header/>
        </View>
        
          {authed === true ? 
          <View style={{paddingTop: 25}}>

            <Text style={{fontSize: 16, fontWeight: 'bold', marginBottom: 15, marginLeft: 25}}>My Cart</Text>

            <FlatList
              showsVerticalScrollIndicator={false}
              data={ticketsall}
              renderItem={renderTickets}
              ItemSeparatorComponent={itemSeperator}
              style={{height: height / 1.5 + 20 - 80, paddingHorizontal: 25}}
            />

            <Animated.View style={{height: 100, width: width - 60, backgroundColor: '#fcfcfc', borderRadius: 20, alignSelf: 'center', position: 'absolute', bottom: 5, justifyContent: 'center', paddingLeft: 15}}>
                <View>
                  <Text style={{fontSize: 12}}>Cart Amount</Text>
                  <Text style={{fontWeight: 'bold', fontSize: 18}}>R{Number(totalPrice).toFixed(2)}</Text>
                </View>

                <TouchableOpacity onPress={()=> navigation.navigate('Payment')} style={{position: 'absolute', right: 15, height: 80, width: 80, marginTop: 5, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 20}}>
                  <Feather name='arrow-right' color={'#fcfcfc'} size={18} />
                  <Text style={{fontWeight: 'bold', color: '#fcfcfc', marginTop: 5}}>PAY</Text>
                </TouchableOpacity>
            </Animated.View>

          </View>
          :



          <View style={{flex: 1, backgroundColor: '#fcfcfc', alignItems: 'center', justifyContent: 'center'}}>
          <Image source={require('../assets/iconsmsall.png')} style={{height: 30, width: 80, resizeMode: 'contain'}} />
          <Text style={{marginTop: 15}}>Loading your cart...</Text>

          <ActivityIndicator size={'small'} color={'purple'} style={{marginTop: 10}} />
        
          </View>
          }
    </View>
  )
}

export default Cart

const styles = StyleSheet.create({})